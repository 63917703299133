.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-modal-dialog {
  max-width: 90vw;
  /* Use viewport width for better scaling on smaller screens */
  width: 100%;
  /* Ensure it takes up the full available width */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 4rem;
  /* Adjust size for smaller screens */
  color: #333;
  cursor: pointer;
  z-index: 1051;
  padding: 5px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: none;
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  padding: 20px;
  /* Reduce padding to make it more compact */
  margin: 50px auto;
  /* Center the modal */
  box-shadow: none;
}

.custom-modal-body {
  background-color: white;
  width: 100%;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  text-align: center;
}

/* Improve image display for responsive design */
.custom-modal-body img {
  max-width: 100%;
  /* Full width for better visibility */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 8px;
  margin: 10px auto;
  display: block;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .custom-modal-dialog {
    max-width: 95vw;
    /* Smaller width for mobile screens */
    padding: 10px;
    /* Less padding on mobile */
  }

  .custom-modal-body {
    padding: 15px;
    /* Reduce padding for smaller screens */
  }

  .close-button {
    font-size: 1.5rem;
    /* Smaller close button on mobile */
  }

  .custom-modal-body img {
    max-width: 90%;
    /* Reduce image width for mobile */
  }
}

@media (max-width: 480px) {
  .custom-modal-dialog {
    max-width: 100vw;
    /* Full width on very small screens */
  }

  .custom-modal-body {
    padding: 10px;
    /* Further reduce padding */
  }

  .custom-modal-body img {
    max-width: 85%;
    /* Adjust image size on very small screens */
  }

  .close-button {
    font-size: 1.2rem;
    /* Further reduce close button size */
  }
}


/* Custom styling for the checkbox */
.custom-checkbox .form-check-input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.custom-checkbox .form-check-label {
  font-size: 1.25rem;
  color: #333;
  font-weight: 500;
}

.custom-checkbox .form-check {
  display: flex;
  align-items: center;
}






/* Center the pagination container and make it horizontal */
.ais-Pagination {
  display: flex;
  justify-content: center;
  /* Horizontally center the pagination */
  margin-top: 20px;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  /* Ensure it stays in one line */
}

/* Style individual pagination items */
.ais-Pagination-item {
  display: inline-flex;
  /* Ensure items are inline */
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 1rem;
  transition: all 0.3s ease;
}

/* Links inside pagination items */
.ais-Pagination-item a {
  text-decoration: none;
  color: #007bff;
  display: flex;
  justify-content: center;
  /* Center the text horizontally */
  align-items: center;
  /* Center the text vertically */
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* Hover and selected states */
.ais-Pagination-item a:hover,
.ais-Pagination-item--selected a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Disabled pagination items */
.ais-Pagination-item--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ais-Pagination-item--disabled a {
  color: #ddd;
}